import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 480,
            md: 768,
            lmd: 1024,
            lg: 1280,
            xl: 1440,
        },
    },
    typography: {
        fontFamily: 'Geologica, Arial, sans-serif',
        h2Bold: {
            fontSize: '34px',
            lineHeight: 1.25,
            fontWeight: 700,
        },
        body1Medium: {
            fontSize: '22px',
            lineHeight: 1.25,
            fontWeight: 500,
        },
        body2Medium: {
            fontSize: '20px',
            lineHeight: 1.25,
            fontWeight: 500,
        },
        body2Bold: {
            fontSize: '20px',
            lineHeight: 1.25,
            fontWeight: 700,
        },
        body4Medium: {
            fontSize: '16px',
            lineHeight: 1.25,
            fontWeight: 500,
        },
        body4Bold: {
            fontSize: '16px',
            lineHeight: 1.25,
            fontWeight: 700,
        },
        captionLight: {
            fontSize: '14px',
            lineHeight: 1.225,
            fontWeight: 300,
        },
        captionSmall: {
            fontSize: '12px',
            lineHeight: 1.25,
            fontWeight: 200,
        },
    },
    palette: {
        mode: 'dark',
        background: {
            default: '#0C0F21',
            white: '#fff',
            blue: '#0666BB',
        },
        text: {
            black: '#2D3137',
            grey: '#A3A5B0',
            azure: '#539AB3',
            darkGrey: '#454B6F',
        },
    },
});

export default theme;
